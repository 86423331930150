import React from "react";
import { Box, useScrollTrigger, } from "@mui/material";


const StickyBox = ({ children }) => {

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 90,
  });

  const top = trigger ? 105 : 0;

  return (
    <Box style={ { position: "sticky", top: top } }>
      {children}
    </Box>

  )
}

export default StickyBox;