import React, { useState, } from "react";
import { Box, List, ListItem, ListItemButton, ListItemText, Collapse } from "@mui/material";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StickyBox from "../sticky-box";


// Adapted from: https://github.com/ratheeshkm/multilevel-menu-react-materialui/blob/master/src/MenuBar.js

const SideBarNavMenu = ({ items }) => {

  const [menu, setMenu] = useState({});
  
  const handleToggleSubMenu = (item) => {
    setMenu({ ...menu, [item] : !menu[item] });
  }

  const buildListItems = ( items, level=0 ) => {
    let lsBtnSx = { p: "0px", '&:hover': { color: "#595d60", background: "none" } };
    level>0 && (lsBtnSx = { ...lsBtnSx, pl: 4 });
    return items.map(({ name, link, items }) => {
      const itemText = <span dangerouslySetInnerHTML={{ __html: name }}></span>;
      if ( !items ) {
        return (
          <List component="div" disablePadding key={ name }>
            <ListItem disableGutters style={ { padding:"0px", margin: "2px" } }>
              <ListItemButton component="a" href={link} sx={lsBtnSx}>
                <ListItemText primary={itemText} />
              </ListItemButton>
            </ListItem>
          </List>
        );
      }
      return (
        <div key={name}>
          <ListItem disableGutters style={ { padding:"0px", margin: "2px" } }>
            <ListItemButton onClick={(e) => handleToggleSubMenu(name)} sx={lsBtnSx}>
              <ListItemText primary={itemText} />
              {
                menu[name] ?
                <ExpandLess style={{fontSize: "20px"}}/> :
                <ExpandMore style={{fontSize: "20px"}}/>
              }
            </ListItemButton>
          </ListItem>
          <Collapse
            in={ (menu[name]) ? true : false }
            timeout="auto"
            unmountOnExit
          >
            { buildListItems(items, 1) }
          </Collapse>
        </div>
      );
    });
  }

  return (
    <StickyBox>
      <List dense={true} style={ { paddingTop: 0, color: "#0696fe" } }>
        { buildListItems(items, 0) }
      </List>
    </StickyBox>
  );
}

export default SideBarNavMenu;