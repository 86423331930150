
import React from "react";
import { Box, Fab, Fade, useScrollTrigger } from "@mui/material";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { styled } from '@mui/material/styles';


const UltraScrollToTopFab = styled(Fab)`
  background: rgba(0, 0, 0, 0.2);
  color: white !important;
  border-radius: 2px;
  padding: 24px 25px;
  box-shadow: none;
  opacity: 1 !important;
  :hover {
    color: white;
    background: rgba(0, 0, 0, 0.2);
  }
`;

const ScrollToTop = () => {
  const scrollTrigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (e) => {
    const anchor = (e.target.ownerDocument || document).querySelector(
      '#entry-header',
    );
    if (anchor) {
      anchor.scrollIntoView({
        block: 'center',
      });
    }
  };

  return (
    <Fade in={scrollTrigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
      >
        <UltraScrollToTopFab size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </UltraScrollToTopFab>
      </Box>
    </Fade>
  );
}

export default ScrollToTop;