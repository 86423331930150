import { useEffect } from 'react';
import { useScrollTrigger } from "@mui/material";


export const useScrollPaddingTop = (unscrolled=116, scrolled=75, threshold=100) => {

  const scrollTrigger = useScrollTrigger({
    disableHysteresis: true,
    threshold,
  });

  const padding = scrollTrigger===true ? scrolled : unscrolled;
  const scrollPadding = scrollTrigger===true ? 95 : 126; // Empirically these seem to be the best values.

  useEffect(() => {
    document.body.style["padding-top"] = `${padding}px`;
    document.documentElement.style["scroll-padding-top"] = `${scrollPadding}px`;
  }, [padding]);

  return padding;

}
