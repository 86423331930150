import React from "react";
import { TwitterTimelineEmbed } from "react-twitter-embed";
import StickyBox from "../sticky-box";


const TwitterFeed = () => {

  return (
    <StickyBox>
      <TwitterTimelineEmbed
        sourceType="profile"
        screenName="CageConcert"
        options={{height: 400}}
      />
    </StickyBox>
  );
}

export default TwitterFeed;