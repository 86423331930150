import React, { useState, useEffect, useContext } from "react";
import { Box } from "@mui/material";
import SideBarNavMenu from "./menu";
import TwitterFeed from "./twitter-feed";
import { getPage } from "../../utils";
import menus from "./menus/menus.json";


const SideBar = () => {

  const [items, setItems] = useState();

  const isBrowser = typeof window !== "undefined";

  const page = isBrowser ? getPage(window.location.href) : "";

  /*
  useEffect(() => {
    if (page) {
      import(`./menus/${page}.json`)
      .then(({default: json}) => setItems(json));
    }
  });
  */

  useEffect(() => {
    if (page) {
      const json = menus[page];
      setItems(json);
    }
  });

  return (
    <Box style={ { paddingRight: "42px", width: "25%" } }>
      { items ? <SideBarNavMenu items={items}/> : <TwitterFeed /> }
    </Box>
  );
}

export default SideBar;
