import { graphql } from "gatsby";
import React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { Container, Stack, useMediaQuery, } from "@mui/material";
import { useScrollPaddingTop } from "../hooks/use-scroll-padding-top";
import EntryHeader from "../components/entry-header";
import ScrollToTop from "../components/scroll-to-top";
import SideBar from "../components/sidebar/sidebar";
import { MDXRenderer } from "gatsby-plugin-mdx";


const fullWidthPages = [
  "/conference",
  "/introduction",
  "/performing-the-concert/apartment-house-and-philip-thomas-perform-the-concert-for-piano-and-orchestra",
  "/project/outputs-and-activities/apartment-house-perform-christian-wolffs-resistance",
  "/apps",
]

export default function SitePageTemplate({ data: { mdx } }) {

  const isDesktop = useMediaQuery("(min-width:1000px)");

  useScrollPaddingTop();

  const { frontmatter, body } = mdx;
  const { title, slug } = frontmatter;

  const isFullWidthPage = fullWidthPages.includes(slug);

  const bodyStyle = {
    padding: (!isDesktop || isFullWidthPage) ? 0 : "0 0 0 3.5%",
    width: (!isDesktop || isFullWidthPage) ? "100%" : "75%",
  };

  return (
    <>
      <Layout>
        <Seo title={title} />
        <EntryHeader title={title}/>
        <Container fixed>
          <Stack direction="row" justifyContent="space-between">
            { isDesktop && !isFullWidthPage && <SideBar/> }
            <div style={ bodyStyle }>
              <MDXRenderer>{body}</MDXRenderer>
            </div>
          </Stack>
        </Container>
      </Layout>
      <ScrollToTop />
    </>
  );
}

export const pageQuery = graphql`
  query ($id: String!) {
    mdx(id: { eq: $id }) {
      body
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
      }
    }
  }
`;
